import React, { Component } from 'react';
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from "react-bootstrap/Card";
import Form from 'react-bootstrap/Form';

import './App.css'

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            averageFuelConsumption: null,
            price: null,
            result: null,
        }
    };

    handleInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    };

    handleSubmit = (event) => {
        event.preventDefault()
        this.calculateFuelConsumption()
    };

    calculateFuelConsumption = () => {
        const { averageFuelConsumption, price } = this.state
        const result = Math.round(((averageFuelConsumption * price /100) + Number.EPSILON) * 100) / 100

        this.setState({
            result: result
        })
    }

    render() {
        const { result } = this.state
        return (
            <Container id='main-container'>
                <Card>
                    <Card.Header>Fuel Price Calculator</Card.Header>
                    <Card.Body>
                        <Card.Title className={'mb-4'}>This calculator is used to calculate the cost of driving one kilometer.</Card.Title>
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group as={Row} controlId="formBasicFuelConsumption">
                                <Form.Label column sm={4}>
                                    Fuel consumption
                                </Form.Label>
                                <Col sm={8}>
                                    <Form.Control
                                        name='averageFuelConsumption'
                                        type="number"
                                        placeholder="l/100 km"
                                        step='0.01'
                                        required
                                        onChange={this.handleInputChange}
                                    />
                                    <Form.Text className="text-muted">
                                        Enter the average fuel consumption
                                    </Form.Text>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPrice">
                                <Form.Label column sm={4}>
                                    Price
                                </Form.Label>
                                <Col sm={8}>
                                    <Form.Control
                                        name='price'
                                        type="number"
                                        placeholder="0,00"
                                        step='0.01'
                                        required
                                        onChange={this.handleInputChange}
                                    />
                                    <Form.Text className="text-muted">
                                        Enter the price per liter of fuel
                                    </Form.Text>
                                </Col>
                            </Form.Group>

                            <Button
                                variant="primary"
                                type="submit"
                                block size="lg"
                            >
                                Calculate
                            </Button>
                        </Form>
                    </Card.Body>
                    <Card.Footer className="text-muted">Your average price for driving a colometer is: { result ?? 0 } </Card.Footer>
                </Card>
            </Container>
        );
    };
};

export default App
